import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2021-stories/more-stories"

import Hero from '../../images/fall-winter-2020/presidents-message/hero-image2.jpg';
import framSignature from "../../images/fall-winter-2020/presidents-message/fram-signature.svg";

import socialBanner from "../../images/fall-winter-2021/social-banners/presidents-message.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'President’s Message';
    var pageDesc = 'As the most populated campus in the largest system of higher education in the nation, Cal State Fullerton has long been the gold standard of the CSU.';
    var slug = 'presidents-message';

    var heroStyles = {
      backgroundColor: '#222',
      backgroundImage: `url(${Hero})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
      backgroundSize: 'cover',
      color: 'white'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2021">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>

        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
          </div>
          <div className="tint"></div>
        </div>
        <article className="wrap small">

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>As the most populated campus in the largest system of higher education in the nation, Cal State Fullerton has long been the gold standard of the CSU. This truth is often accentuated during times of challenge and crisis as our sister campuses and the world look to us for leadership and comfort. Never has that been more evident than these last 19 months as we set the CSU standard in everything from virtual teaching and telecommuting policies to masking and vaccination mandates.</p>
          </ScrollAnimation>
 
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>Today, with nearly 65% of our classes in person and about 90% of our students having at least one face-to-face class, we have more students on our campus than most any other university in the state. At the same time, we are one of the safest places to be in California, with the overwhelming majority of our faculty, staff and students in compliance with our systemwide vaccination mandates. On top of all that, this past June, we conferred the most bachelor’s degrees to Black and Latinx Titans than in any other year in our history; more women earned their bachelor’s AND doctoral degrees than in any other year in our history; and we hit all-time highs in two-, four- and six-year graduation rates.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>Given that our faculty, staff and students did all that while leading Cal State Fullerton and the CSU through the adversity and heartache brought on by both COVID-19 and the systemic racism that remains our nation’s true ongoing plague, it would be understandable if the Titan achievements stopped there.</p>
          </ScrollAnimation>
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>Titan magazine spoiler alert: They don’t.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>Indeed, after learning about so many other incredible Titan achievements in these pages — from our student-faculty research team developing an early wildfire detection system and alumna Helen Yoshida’s work in our Center for Oral and Public History, to our gold medal-winning Titan Olympians and alumnus Brian Crosby’s creative direction of Marvel Themed Entertainment — I am reminded of what it takes to persevere, lead and succeed in the face of unprecedented adversity.</p>
          </ScrollAnimation>
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>It takes tenacity and grit. It takes kindness and care. It takes diversity and inclusion. It takes collaboration and academic excellence. And above all, it takes the investment of time, treasure and talent to yield the greatest possible return in both good and challenging times: an educated workforce that reflects our nation’s rich and vibrant diversity.</p>
          </ScrollAnimation>
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>In other words, It Takes a Titan.</p>
          </ScrollAnimation>
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>Oh, and it also takes a Titan magazine to capture that truth with such engaging excellence. If you are like me, you will enjoy every word in these pages while reflecting on all we have lost, all we have overcome and all that we have yet to achieve.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p style={{lineHeight: '1.2em'}}>
            Sincerely,<br /><br />
            <img src={framSignature} alt="Fram Virjee" width="200px" /><br />
            Framroze M. Virjee, JD<br />
            President<br />
            California State University, Fullerton
          </p>
          </ScrollAnimation>

        </article>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 